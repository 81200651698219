<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ this.idEdit ? this.$t('update') : this.$t('create') + ' ' + this.$t("mobile_settings")}}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label for="app_id">{{ $t('app_id') }}<span class="text-danger">*</span></label>
                <input disabled id="app_id" :value="data.app_id" :class="validation && validation.app_id ? 'is-invalid' : ''"
                       :placeholder="$t('app_id')" class="form-control"
                       type="text">
                <span v-if="validation && validation.app_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.app_id[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label for="slug">{{ $t('app_slug') }}</label>
                <input id="slug" v-model="data.slug" :class="validation && validation.slug ? 'is-invalid' : ''"
                       :placeholder="$t('app_slug')" class="form-control"
                       type="text">
                <span v-if="validation && validation.slug" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.slug[0] }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('expenses_income_transaction.date') }}</label>
                <input type="date" v-model="data.issued_date" class="form-control" :class="validation && validation.issued_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.issued_date" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.issued_date[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label for="notes">{{ $t('laundry_services.notes') }}</label>
                <textarea id="notes" v-model="data.description" class="form-control" type="text"></textarea>
                <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.description[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5">
                <b-form-checkbox v-model="data.is_active" name="check-button" size="lg" switch>{{
                    $t('status')
                  }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row mt-5">
        <div class="col-md-12">
          <div class="d-flex justify-content-between mt-2 p-2 bg-FFB803" style="height: 45px;">
            <h6 class="my-auto text-white">{{ $t('modules') }}</h6>
            <button class="btn btn-primary btn-sm" type="button" @click="addItemRowToList">{{ $t('add_more') }}</button>
          </div>
        </div>
        <div class="col-md-12">
          <div class="table-responsive bg-white">
            <table class="table table-row-bordered w-100" @keyup.alt.enter="addItemRowToList"
                   @keyup.alt.46="removeItemRowFromList(0)">
              <thead>
              <tr>
                <th width="45%"> {{ $t('main_module') }}</th>
                <th width="45%">{{ $t('sub_module') }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in sub_modules" :key="index">
                <td>
                  <multiselect
                      v-model="row.selectedMainModule"
                      :key="'selectedMainModule'+ index"
                      :multiple="false"
                      :options="modules_options"
                      :placeholder="$t('main_module')"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      :taggable="false"
                      class="w-100"
                      label="name"
                      track-by="id"
                      @input="selectedMainModules(index)">
                  </multiselect>
                </td>
                <td>
                  <multiselect
                      v-model="row.selected_sub_module"
                      :key="'selected_sub_module'+ index"
                      :multiple="true"
                      :options="sub_module_options"
                      :placeholder="$t('sub_module')"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      :taggable="false"
                      class="w-100"
                      label="category_label"
                      track-by="id"
                      @input="selectedSubModules(index)">
                  </multiselect>
                </td>
                <td>
                  <v-icon v-if="sub_modules.length > 1" small style="color: #dc3545;"
                          @click="removeItemRowFromList(index)">
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-5">
            <button class="btn btn-primary mr-2" type="button" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-mobile_app_settings",
  data() {
    return {
      mainRoute: 'settings/mobile_app_settings',
      mainRouteDependency: 'base/dependency',
      idEdit: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      sub_modules: [],
      sub_module_options: [],
      modules_options: [],
      validation: null,
      data: {
        app_id: 1,
        slug: null,
        issued_date: null,
        description: null,
        module_details: '',
        is_active: true,
      },
      sub_modules_form: {
        selected_sub_module: [],
        sub_module: null,
        selectedMainModule: null,
        main_module: null,
      },
    };
  },

  watch: {},
  methods: {
    save() {
      let $url = this.idEdit ? this.mainRoute + '/' + this.idEdit : this.mainRoute;
      let $method = this.isEditing ? 'put' : 'post';
      ApiService[$method]($url, {
        ...this.data,
        sub_modules: this.sub_modules,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/mobile-app-settings');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        let $data = response.data.data;
        this.data = {
          app_id: $data.app_id,
          slug: $data.slug,
          issued_date: $data.issued_date,
          description: $data.description,
          module_details: $data.module_details,
          is_active: $data.is_active,
        };
        if (response.data.data.sub_modules) {
          this.sub_modules = response.data.data.sub_modules;
          this.sub_module_options.length > 0 && this.setSelectedSubModulesAfterLoadDate(this.sub_modules);
          this.modules_options.length > 0 && this.setSelectedMainModulesAfterLoadDate(this.sub_modules);
        } else {
          this.addItemRowToList();
        }
      });
    },
    selectedMainModules(idx) {
      this.sub_modules[idx].main_module = this.sub_modules[idx].selectedMainModule.id;
    },
    selectedSubModules(idx) {
      this.sub_modules[idx].sub_module = this.sub_modules[idx].selected_sub_module.map((row) => {
        return row.id;
      });
    },

    async getSubModules() {
      await ApiService.get(`${this.mainRouteDependency}/sub_modules?module=Sales&prototype_number=1`).then((response) => {
        this.sub_module_options = response.data.data;
      })
    },
    async getModulesOptions() {
      await ApiService.get(`${this.mainRouteDependency}/modules`).then((response) => {
        this.modules_options = response.data.data;
      })
    },
    setSelectedSubModulesAfterLoadDate(md) {
      md.map((row, index) => {
        this.sub_modules[index].selected_sub_module = this.sub_module_options.filter((module) => {
          return row.sub_module.includes(module.id);
        });
      });
    },
    setSelectedMainModulesAfterLoadDate(md) {
      md.map((row, index) => {
        this.sub_modules[index].selectedMainModule = this.modules_options.filter((module) => {
          return module.id === row.main_module;
        })[0];
      });
    },
    addItemRowToList() {
      this.sub_modules.unshift(this.sub_modules_form);
      this.sub_modules_form = {
        selected_sub_module: [],
        sub_module: null,
        selectedMainModule: [],
        main_module: [],
      };
    },
    removeItemRowFromList(index) {
      if (this.sub_modules.length > 1) {
        this.sub_modules.splice(index, 1);
      }
    },
  },

  computed: {},

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.mobile_app_settings"),
      route: '/settings/mobile-app-settings'
    }, {
      title: this.idEdit ? this.$t('update') : this.$t('create') + ' ' + this.$t("mobile_settings"),
    }]);

  },
  created() {
    let that = this;
    if (this.idEdit) {
      this.getSubModules().then(() => {
        that.getModulesOptions().then(() => {
          that.getData();
        });
      });
    } else {
      this.getModulesOptions().then(() => {
        this.getSubModules();
        this.addItemRowToList();
      });
    }
  }
};
</script>


